<template>
  <div class="flex flex-col">
    <div class="flex">
      <span class="font-semibold"> Mandate arketimi </span>
      <input
        v-model="useCreditnoteStore().search_creditnote_val"
        @input="handleSearchInput"
        type="text"
        placeholder="Kerko ... "
        class="border rounded px-1 w-64 ml-2 text-10px" />
    </div>
    <div>
      <table class="table is-fullwidth is-narrow text-14px">
        <thead>
          <tr>
            <th>Tipi i mandatit</th>
            <th>Klienti</th>
            <th><div class="text-right">Vlera</div></th>
            <th>Krijuar nga</th>
            <th width="150">Krijuar me</th>
            <th>Koment</th>
            <th>
              <div
                @click="useCreditnoteStore().toggle_create_new()"
                class="text-right" >
                <span
                  class="bg-green-400 border rounded border-green-800 text-white px-1 cursor-pointer" >
                  <i class="fa-solid fa-plus"></i>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(creditnote, index) in slicedCreditnotes" :key="index">
            <td>{{ creditnote.type_name }}</td>
            <td>{{ creditnote.customer_name }}</td>
            <td>
              <div class="text-right">
                {{ useDiaryStore().price_value_filter(creditnote.amount) }}
              </div>
            </td>
            <td>{{ creditnote.created_by_name }}</td>
            <td>{{ useDiaryStore().formatDate(creditnote.created_at) }}</td>
            <td><div>{{creditnote.comment}}</div></td>
            <td><div class="text-right">#</div></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <button @click="previousPage" :disabled="currentPage === 1">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <span>{{ currentPage }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
    <div v-if="useCreditnoteStore().show_create_creditnote" 
      @click.self="useCreditnoteStore().show_create_creditnote = false" 
      class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center flex items-center justify-center" 
      style="z-index: 9;">
      <div class="bg-white flex flex-col w-5/6 mx-3 rounded overflow-y text-14px"
        style="height:60vh; overflow-y:scroll;">
        <!-- Modal header -->
        <div class="p-2 border-b text-center flex justify-between items-center">
            <div class="w-1/3"></div>
            <div class="w-1/3 text-center font-semibold"> Krijo mandat te ri </div>
            <div class="w-1/3"></div>
        </div>
        <div class="py-1 px-2 flex-grow">
          <div class="flex flex-col" style="height:100%;">
            <div class="flex gap-x-1 justify-between">
              <div class="w-1/3 ">
                <div class="flex flex-col">
                  <span class="text-slate-400"> Klienti </span>
                  <div class="flex justify-bwetween">
                    <div
                      v-if="!useCreditnoteStore().new_creditnote.customer?.guid"
                      class="relative flex flex-grow" >
                      <input
                        v-model="useCreditnoteStore().search_customer_val"
                        class="w-full flex-grow px-1  border rounded"
                        type="text"
                        placeholder="Kerko klient"
                      />
                      <div
                        v-if="useCreditnoteStore().search_customer_val.length > 0"
                        class="absolute top-6 w-full border bg-white dropdown-box-shadow flex flex-col">
                        <div
                          v-for="(customer, index) in useCreditnoteStore()
                            .filtered_customers"
                          :key="index"
                          @click="useCreditnoteStore().select_customer(customer)"
                          class="p-1 border-b hover:bg-slate-100 cursor-pointer" >
                          {{ customer.name }}
                        </div>
                      </div>
                    </div>
                    <div v-else
                      class="flex justify-between w-full px-1 bg-white border rounded" >
                      <span>
                        <i class="fa-solid fa-user mx-1 text-14px"></i>
                        {{ useCreditnoteStore().new_creditnote.customer?.name }}
                      </span>
                      <span
                        @click="useCreditnoteStore().new_creditnote.customer = {}"
                        class="cursor-pointer text-red-400">
                        <i class="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-1/3 ">
                <div class="flex flex-col text-right">
                  <span class="text-slate-400"> Vlera e mandatit </span>
                  <input
                    v-model="useCreditnoteStore().new_creditnote.amount"
                    placeholder="Vlera e mandatit"
                    class="border rounded px-1 text-right"
                    type="number"
                  />
                </div>
              </div>
              <div class="w-1/3 ">
                <div class="flex flex-col text-right">
                  <span class="text-slate-400">
                    Tipi i mandatit
                  </span>
                  <select v-model="useCreditnoteStore().new_creditnote.creditnote_type"
                    class="border rounded text-right">
                    <option v-for="(type, index) in useCreditnoteStore().creditnote_types" :key="index" :value="type.id">
                      {{ type.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex flex-col mt-2 ">
              <span class="text-slate-400">
                Koment / shenime
              </span>
                <textarea v-model="useCreditnoteStore().new_creditnote.comment" 
                  class="border rounded px-2" rows="2"></textarea>
            </div>
            <div class="flex mt-2 items-end justify-center flex-grow">
              <a v-if="useCreditnoteStore().can_create_creditnote"
                @click.prevent="start_create_new_creditnote()"
                class="w-1/2 margin-x-auto text-center bg-green-400 border rounded border-green-800 text-white"
                href=""
                >Krijo</a>
              <a v-else
                @click.prevent="() => {}"
                class="w-1/2 margin-x-auto text-center bg-green-400 border rounded border-green-800 text-white opacity-50"
                href=""
                >Krijo</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCreditnoteStore } from "@/stores/creditnote";
import { useDiaryStore } from "@/stores/diary";
</script>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  methods: {
    handleSearchInput() {
      this.currentPage = 1;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async start_create_new_creditnote(){
      var a = await useCreditnoteStore().create_new_creditnote()
      if(a.message == 'success'){
        this.$toast.success('Mandati u krijua me sukses!')
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(
        useCreditnoteStore().filtered_creditnotes.length / this.itemsPerPage
      );
    },
    slicedCreditnotes() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      const filteredCreditnotes =
        useCreditnoteStore().filtered_creditnotes || [];
      return filteredCreditnotes.slice(start, end);
    },
  },
  watch: {
    "useCreditnoteStore().filtered_creditnotes": "handleSearchInput",
  },
};
</script>

<style>
.dropdown-box-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
  rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
